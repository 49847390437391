<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" class="mb-2" v-b-modal.new-modal>ثبت فروشنده</b-button>
    <b-card title="فروشندگان">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="title">نام</label>
                  <b-form-input id="title" v-model="searchParams.first_name"></b-form-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="last_name">نام خانوادگی</label>
                  <b-form-input id="last_name" v-model="searchParams.last_name"></b-form-input>
                </div>
              </div>
              <div class="col-md-4">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="pending">درحال انتظار</b-form-select-option>
                    <b-form-select-option value="approved">تایید شده</b-form-select-option>
                    <b-form-select-option value="disapproved">رد شده</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(status)="data">
          <span class="badge badge-warning" v-if="data.item.status == 'pending'">درحال انتظار</span>
          <span class="badge badge-success" v-else-if="data.item.status == 'approved'">تایید شده</span>
          <span class="badge badge-danger" v-else-if="data.item.status == 'disapproved'">رد شده</span>
        </template>

        <template v-slot:cell(provide)="data">
          <router-link :to="{ name: 'provides.seller', params: { id: data.item.id } }" class="btn btn-warning"> <i
              class="fa fa-info"></i></router-link>
        </template>
        <template v-slot:cell(show)="data">
          <button class="btn btn-info" @click="showItem(data.item)"><i class="fa fa-eye"></i></button>
        </template>
        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="new-modal" title="ثبت فروشنده" hide-footer size="lg">
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label for="first_name"> نام <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="first_name" name="name" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="last_name"> نام خانوادگی <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="last_name" name="last_name" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="username"> نام کاربری <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="username" name="username" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="password"> رمز عبور <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="password" name="password" type="password" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="phone_number"> شماره تماس <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="phone_number" name="phone_number" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="email"> پست الکترونیکی <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="email" name="email"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="ownership"> مالکیت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-select id="ownership" name="ownership">
                  <b-form-select-option value="legal">حقوقی</b-form-select-option>
                  <b-form-select-option value="character">حقیقی</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="company-name"> نام شرکت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="company-name" name="company_name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="استان">
                <multiselect v-model="selected_province" open-direction="bottom" :options="provinces" label="name"
                  track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" @input="loadCities"
                  :disabled="disabled">
                </multiselect>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="city"> شهر <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <multiselect v-model="selected_city" open-direction="bottom" :options="cities" label="name"
                  track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled">
                </multiselect>
                <input type="hidden" name="city_id" :value="selected_city != null ? selected_city.id : ''">
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="category"> دسته بندی <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <multiselect open-direction="bottom" v-model="selected_categories" :options="categories" label="name"
                  track-by="id" :multiple="true" :searchable="true" :close-on-select="true" :show-labels="false">
                </multiselect>
                <div v-for="item in selected_categories" :key="item.id">
                  <input type="hidden" name="categories[]" :value="item.id">
                </div>
              </b-form-group>
            </div>
            <!-- <div class="col-md-6">
              <b-form-group>
                <label for="product-group"> گروه کالا <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <multiselect v-model="selected_product_group" open-direction="bottom" :options="product_groups"
                  label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false"
                  :disabled="disabled">
                </multiselect>
                <input type="hidden" name="product_group_id"
                  :value="selected_product_group != null ? selected_product_group.id : ''">
              </b-form-group>
            </div> -->
            <div class="col-md-6">
              <b-form-group>
                <label for="varieties-count"> تعداد تنوع های محصول <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-select id="varieties-count" name="product_varieties_count">
                  <b-form-select-option value="zero_to_fifty">0 تا 50</b-form-select-option>
                  <b-form-select-option value="fifty_to_hundred">50 تا 100</b-form-select-option>
                  <b-form-select-option value="higher_than_hundred">بالای 100</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="status"> وضعیت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-select id="status" name="status">
                  <b-form-select-option value="pending">در حال انتظار</b-form-select-option>
                  <b-form-select-option value="approved">تایید شده</b-form-select-option>
                  <b-form-select-option value="disapproved">رد شده</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="our-percentage"> سهم آلبا  (درصد) <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="our-percentage" name="our_percentage" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="status"> تصویر جواز </label>
                <b-form-file name="license_image" v-model="file" @change="addImages($event)" accept="image/*">
                </b-form-file>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <span v-if="myImage != ''" class="position-relative d-block ">
                <span class="bg-danger rounded-circle text-white position-absolute close-item px-2"
                  @click="removeImage"><i class="fa fa-times py-1" style="font-size:15px !important;"
                    aria-hidden="true"></i></span>
                <b-img :src="myImage" class="rounded" style="width: 100px; height: 100px"></b-img>
              </span>
            </div>
          </div>

          <div class="clearfix mt-2">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش فروشنده" hide-footer size="lg">
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label for="first_name"> نام <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="first_name" name="name" v-model="edit.name" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="last_name"> نام خانوادگی <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="last_name" name="last_name" v-model="edit.last_name" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="username"> نام کاربری <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="username" name="username" v-model="edit.username" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="password"> رمز عبور <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="password" name="password" type="password" data-required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="phone_number"> شماره تماس <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="phone_number" name="phone_number" v-model="edit.phone_number" data-required>
                </b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="email"> پست الکترونیکی <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="email" name="email" v-model="edit.email"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="ownership"> مالکیت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-select id="ownership" name="ownership" v-model="edit.ownership">
                  <b-form-select-option value="legal">حقوقی</b-form-select-option>
                  <b-form-select-option value="character">حقیقی</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="company-name"> نام شرکت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="company-name" name="company_name" v-model="edit.company_name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="استان">
                <multiselect v-model="selected_province" open-direction="bottom" :options="provinces" label="name"
                  track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" @input="loadCities"
                  :disabled="disabled">
                </multiselect>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="city"> شهر <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <multiselect v-model="selected_city" open-direction="bottom" :options="cities" label="name"
                  track-by="id" :searchable="true" :close-on-select="true" :show-labels="false" :disabled="disabled">
                </multiselect>
                <input type="hidden" name="city_id" :value="selected_city != null ? selected_city.id : ''">
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="category"> دسته بندی <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <multiselect open-direction="bottom" v-model="selected_categories" :options="categories" label="name"
                  track-by="id" :multiple="true" :searchable="true" :show-labels="false"></multiselect>
                <div v-for="item in selected_categories" :key="item.id">
                  <input type="hidden" name="categories[]" :value="item.id">
                </div>
              </b-form-group>
            </div>
            <!-- <div class="col-md-6">
              <b-form-group>
                <label for="product-group"> گروه کالا <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <multiselect v-model="selected_product_group" open-direction="bottom" :options="product_groups"
                  label="name" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false"
                  :disabled="disabled">
                </multiselect>
                <input type="hidden" name="product_group_id"
                  :value="selected_product_group != null ? selected_product_group.id : ''">
              </b-form-group>
            </div> -->
            <div class="col-md-6">
              <b-form-group>
                <label for="varieties-count"> تعداد تنوع های محصول <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-select id="varieties-count" name="product_varieties_count"
                  v-model="edit.product_varieties_count">
                  <b-form-select-option value="zero_to_fifty">0 تا 50</b-form-select-option>
                  <b-form-select-option value="fifty_to_hundred">50 تا 100</b-form-select-option>
                  <b-form-select-option value="higher_than_hundred">بالای 100</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="status"> وضعیت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-select id="status" name="status" v-model="edit.status">
                  <b-form-select-option value="pending">در حال انتظار</b-form-select-option>
                  <b-form-select-option value="approved">تایید شده</b-form-select-option>
                  <b-form-select-option value="disapproved">رد شده</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="our-percentage"> سهم آلبا  (درصد) <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="our-percentage" name="our_percentage" v-model="edit.our_percentage"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="status"> تصویر جواز </label>
                <b-form-file name="license_image" accept="image/*" v-model="file" @change="addImages($event)">
                </b-form-file>
              </b-form-group>
            </div>
            <div v-if="myImage" class="col-md-6">
              <a :href="myImage" class="rounded">
                <img :src="myImage" style="max-width: 200px; max-height: 200px;">
              </a>
            </div>
          </div>

          <div class="clearfix mt-2">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="put" name="_method">
        </form>
      </div>
    </b-modal>
    <b-modal id="show-modal" title="مشاهده فروشنده" hide-footer size="lg">
      <div v-if="edit" class="row">
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">نام و نام خانوادگی : </span>
          <span>{{edit.name + ' ' + edit.last_name}}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">نام کاربری : </span>
          <span>{{edit.username}}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">شماره تماس : </span>
          <span>{{edit.phone_number}}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">پست الکترونیکی : </span>
          <span>{{edit.email}}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">مالکیت : </span>
          <span v-if="edit.ownership == 'legal'">حقوقی</span>
          <span v-else-if="edit.ownership == 'character'">حقیقی</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">نام شرکت : </span>
          <span>{{edit.company_name}}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">دسته‌بندی : </span>
          <span v-for="(item, index) in edit.categories" :key="index">{{item.name}}
            {{ index != edit.categories.length -1 ? ' - ' : '' }}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">تعداد تنوع های محصول : </span>
          <span v-if="edit.product_varieties_count == 'zero_to_fifty'">0 تا 50</span>
          <span v-else-if="edit.product_varieties_count == 'fifty_to_hundred'">50 تا 100</span>
          <span v-else-if="edit.product_varieties_count == 'higher_than_hundred'">بالای 100</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">شهر : </span>
          <span>{{edit.city ? edit.city.name : edit.city_id}}</span>
        </div>
        <div class="col-md-6 py-1">
          <span class="fontWeightBold">وضعیت : </span>
          <span class="badge badge-warning" v-if="edit.status == 'pending'">درحال انتظار</span>
          <span class="badge badge-success" v-else-if="edit.status == 'approved'">تایید شده</span>
          <span class="badge badge-danger" v-else-if="edit.status == 'disapproved'">رد شده</span>
        </div>

        <div class="mt-2 pr-2 col-md-6">
          <b-button @click="approveItem" variant="success"
            :disabled="edit.status == 'approved' || disabled ? true : false">تایید فروشنده
          </b-button>
          <b-button @click="disapproveItem" variant="danger" class="mr-1"
            :disabled="edit.status == 'disapproved' || disabled ? true : false">رد فروشنده
          </b-button>
        </div>
        <div v-if="edit.license_image" class="col-md-6 py-1">
          <a :href="edit.license_image.url" class="rounded">
            <img :src="edit.license_image.url" style="max-width: 200px; max-height: 200px;">
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/sellers',
        title: 'فروشندگان',
        customers: [],
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'name',
            label: 'نام'
          },
          {
            key: 'last_name',
            label: ' نام خانوادگی'
          },
          {
            key: 'phone_number',
            label: 'موبایل'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'provide',
            label: 'سفارشات'
          },
          {
            key: 'show',
            label: 'مشاهده'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          },
        ],
        selected_status: '1',
        selected_filter_status: '',
        cities: [],
        product_groups: [],
        provinces: [],
        selected_city: '',
        selected_province: '',
        selected_product_group: '',
        categories: [],
        selected_categories: '',
        categories_id: [],
        file: [],
        myImage: [],
      }
    },
    methods: {
      addImages(event) {
        const data = URL.createObjectURL(event.target.files[0]);
        this.myImage = data;
      },
      removeImage() {
        this.file = [];
        this.myImage = [];
      },
      editItem(index) {
        this.edit = {}
        let item = window.clone(this.items.data.data[index])
        item.index = index
        this.edit = item
        this.selected_province = this.edit.city ? this.provinces.filter(item => item.id == this.edit.city.province_id)[
          0] : ''
        this.selected_city = this.edit.city
        this.selected_categories = this.edit.categories
        this.myImage = this.edit.license_image ? this.edit.license_image.url : []
        this.$root.$emit('bv::show::modal', 'edit-modal')
      },
      // loadProductGroups() {
      //   this.$axios.get(this.$root.baseUrl + '/api/admin/product_groups?all=true')
      //     .then(response => {
      //       this.product_groups = response.data.data
      //     })
      // },
      loadCities(item) {
        this.cities = item.cities
      },
      loadProvinces() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/list/provinces/102?all=true')
          .then(response => {
            this.provinces = response.data.data
          })
      },
      loadCategories() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/categories?all=true')
          .then(response => {
            this.categories = response.data.data
          })
      },
      approveItem() {
        this.disabled = true
        this.$axios.post(this.$root.baseUrl + `/api/admin/sellers/${this.edit.id}/approve`)
          .then(response => {
            this.edit.status = response.data.data.status
            // window.swal(response.data.message)
            this.$root.success_notification(response.data.message)
            this.disabled = false
            this.items.data.data[this.edit.index].status = response.data.data.status
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      disapproveItem() {
        this.disabled = true
        this.$axios.post(this.$root.baseUrl + `/api/admin/sellers/${this.edit.id}/disapprove`)
          .then(response => {
            this.edit.status = response.data.data.status
            // window.swal(response.data.message)
            this.$root.success_notification(response.data.message)
            this.disabled = false
            this.items.data.data[this.edit.index].status = response.data.data.status
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      newItem() {
        this.disabled = true
        let form = document.getElementById('new-item')
        let formData = new FormData(form)

        this.$axios.post(this.$root.baseUrl + this.url, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.items.data.data.unshift(response.data.data)
            this.$root.$emit('bv::hide::modal', 'new-modal')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      itemEdited() {
        this.disabled = true
        let form = document.getElementById('edit-item')
        let formData = new FormData(form)

        this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}`, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$root.$set(this.items.data.data, this.edit.index, response.data.data)
            this.$root.$emit('bv::hide::modal', 'edit-modal')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      searchItems(page = 1) {
        this.disabled = true
        this.search = true
        this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
            params: {
              search1: this.searchParams.first_name,
              searchBy1: 'name',
              search2: this.searchParams.last_name,
              searchBy2: 'last_name',
              search3: this.searchParams.status,
              searchBy3: 'status'
            }
          })
          .then(response => {
            this.search = true
            this.items = response.data
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    },
    created() {
      this.loadItems()
      // this.loadProductGroups()
      this.loadProvinces()
      this.loadCategories()
      this.searchParams = {
        first_name: '',
        status: '',
        last_name: '',
      }
    }
  }
</script>